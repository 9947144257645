@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiDrawer-root a[href^="#/rfq-supplier-response"] {
  display: none;
}

.MuiDrawer-root a[href^="#/rfq-response"] {
  display: none;
}
.MuiDrawer-root a[href^="#/supplier-product-pricing"] {
  display: none;
}

input:disabled {
  background-color: white;
  border-style: solid;
  border-width: 1px;
}

/* .MuiInputLabel-sizeSmall {
  font-weight: 700 !important;
  font-size: 18px !important;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;
